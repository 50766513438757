import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
export const routes: Routes = [
  {
    path: 'tickets', loadChildren: () => import('./pages/ticket/ticket.module').then(m => m.TicketModule), canActivate: []
  },
  {
    path: 'admin', canActivate: [], children: [
      { path: 'clients', loadChildren: () => import('./pages/clients/client.module').then(m => m.ClientModule), },
      { path: 'users', loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule) }
    ]
  },
  {
    path: '', redirectTo: 'tickets', pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
