import { Component, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { MeClient } from './web-api-client';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'app';
  isEmployee: boolean = false;
  name: string = '';


  constructor(client: MeClient) {
    client.me().subscribe(x => {
      this.isEmployee = x.isEmployee;
      document.getElementById("splashScreen").style.display = 'none'
    },
      error => {
        console.log(error);
        var currentUrl = new URL(`${window.location.protocol}${window.location.host}/login`);
        currentUrl.searchParams.append("returnUrl", window.location.href);

        window.location.href = currentUrl.toString();
      });
  }
  ngOnInit(): void {
  }

}