import { createAction, props } from '@ngrx/store';
import { CreateTicketCommand, PaginatedListOfTicketItem, TicketDetailVm, TicketItem } from '../../../web-api-client';

export const loadTickets = createAction(
  "[TICKET] Load Tickets",
  props<{ page: number, filter: string }>()
)

export const loadTicketsSuccess = createAction(
  "[TICKET] Load Tickets Success",
  props<{ tickets: PaginatedListOfTicketItem }>()
)

export const createTicket = createAction(
  "[TICKET] Create Ticket",
  props<{ ticket: CreateTicketCommand }>()
)

export const loadTicket = createAction(
  "[TICKET] Load Ticket",
  props<{ id: string }>()
)

export const loadTicketSuccess = createAction(
  "[TICKET] Load Ticket Success",
  props<{ ticket: TicketDetailVm }>()
)

export const clearTicket = createAction(
  "[TICKET STATE] Reset Ticket"
)


