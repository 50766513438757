import { state } from '@angular/animations';
import { Action, createReducer, on } from '@ngrx/store';
import { ITicketDetailVm, PaginatedListOfTicketItem, TicketItem } from '../../../web-api-client';
import { clearTicket, loadTicket } from '.';
import { loadTickets, loadTicketsSuccess, loadTicketSuccess } from './ticket.actions';

export interface TicketState {
  ticket: ITicketDetailVm
  tickets: PaginatedListOfTicketItem
  error: string
  status: 'loading' | 'error' | 'success'
};

export const initialTicketState: TicketState = {
  status: 'loading',
  ticket: null,
  tickets: null,
  error: null
}

const reducer = createReducer(initialTicketState,
  on(loadTicket, (state, { }) => ({ ...state, status: 'loading' })),
  on(loadTicketSuccess, (state, { ticket }) => ({ ...state, status: 'success', ticket })),
  on(loadTickets, (state, {}) => ({ ...state, status: 'loading' })),
  on(loadTicketsSuccess, (state, { tickets }) => ({ ...state, status: 'success', tickets })),
  on(clearTicket, (state) => (initialTicketState))
);

export function ticketReducer(state: TicketState, action: Action) {
  return reducer(state, action);
}
