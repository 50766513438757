import { ActionReducerMap } from "@ngrx/store";
import { ticketReducer, TicketState } from './reducers';

export * from '../pages/ticket/+state';
export * from './reducers';
export * from './selectors';

export interface RootState {
  ticket: TicketState
}

export const reducers: ActionReducerMap<RootState> = {
  ticket: ticketReducer
};
